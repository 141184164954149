import {
  SidebarContainerCustom,
  CustomList,
} from 'components/sidebar/navigation-sidebar/navigation-sidebar.styles';
import { useOnClickOutside, useSidebar } from 'hooks';
import { SidebarTypeEnum } from 'hooks/useSidebar';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import Box from '@mui/material/Box';
import { LogoIcon } from 'components/icons/LogoIcon';
import CloseIcon from '@mui/icons-material/Close';
import { useNavigate } from 'react-router-dom';
import { useRef } from 'react';
import { UserRoleEnum } from 'enums';
import { Typography } from '@mui/material';

export const NavigationSidebar = () => {
  const sidebarStore = useSidebar();
  const navigate = useNavigate();
  const sidebarContainerRef = useRef<HTMLDivElement>(null);
  useOnClickOutside<HTMLDivElement>(sidebarContainerRef, () => {
    sidebarStore.onClose();
  });
  const user = JSON.parse(
    localStorage.getItem('user') ? localStorage.getItem('user') : '{}'
  );
  const handleAddressClick = () => {
    navigate('/addresses');
    sidebarStore.onClose();
  };

  const handleUsersClick = () => {
    navigate('/users');
    sidebarStore.onClose();
  };

  const handleDashboardClick = () => {
     navigate('/dashboard');
    sidebarStore.onClose();
  };
 
  const programDashboardUsers = ['benefit', 'ilia', 'glow'] 
  
  const canAccessProgramDashboard = () => {
    
    if (user?.role!==UserRoleEnum.USER) return false
      const userProgram= user?.programs[0].toLowerCase();
      return programDashboardUsers.includes(userProgram) ?? false
  };
  return (
    <SidebarContainerCustom
      showSidebar={sidebarStore.showModal === SidebarTypeEnum.NAVIGATION}
      ref={sidebarContainerRef}
    >
      <CloseIcon
        onClick={sidebarStore.onClose}
        sx={{
          position: 'absolute',
          right: '10px',
          top: '15px',
          cursor: 'pointer',
        }}
      />
      <Box display="flex" justifyContent="center">
        <LogoIcon
          style={{
            width: '80px',
          }}
        />
      </Box>
    
      <CustomList>
      <Typography sx={{
            fontSize: '20px',
            fontWeight: '600',
            textAlign: 'center',
            color:'#089aa2',            
            padding: '10px 20px',
            marginBottom:'10px',
            borderRadius: '8px',
            boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
          }}>
        {user?.role===UserRoleEnum.USER ? user?.programs.join() : user?.role }
      </Typography>
        <ListItemButton onClick={handleDashboardClick}>
          <ListItemText primary="Brand Dashboard" />
        </ListItemButton>

       {canAccessProgramDashboard() && <ListItemButton onClick={() => navigate('/dashboard?dashboardType=program')} >
          <ListItemText primary="Program Dashboard" />
        </ListItemButton>}

        <ListItemButton onClick={handleAddressClick}>
          <ListItemText primary="Addresses" />
        </ListItemButton>

        {user && user.role == UserRoleEnum.SUPER_ADMIN ? (
          <ListItemButton onClick={handleUsersClick}>
            <ListItemText primary="Users" />
          </ListItemButton>
        ) : null}
      </CustomList>
    </SidebarContainerCustom>
  );
};
