export const DashboardIframe = [
  {
    role: 'superadmin',
    program: 'pact',
    iframeUrl: `https://app.trevor.io/embedded/dashboard/f2904e01-ce00-4fdd-95eb-c36d5e94c573`,
  },
  {
    role: 'admin',
    program: 'pact',
    iframeUrl: `https://app.trevor.io/embedded/dashboard/f2904e01-ce00-4fdd-95eb-c36d5e94c573`,
  },
  {
    role: 'user',
    program: 'benefit',
    iframeUrl: `https://app.trevor.io/embedded/dashboard/d1ef14ad-d618-4f27-9ae4-39bebfeea0eb`,
    programUrl: `https://app.trevor.io/embedded/dashboard/faaaad10-9ab8-4f21-b6d3-d9d6d51ab988`
  },
  {
    role: 'user',
    program: 'ilia',
    iframeUrl: `https://app.trevor.io/embedded/dashboard/ab3d2701-b0b1-4682-b33d-2a181b106c04`,
    programUrl: `https://app.trevor.io/embedded/dashboard/faaaad10-9ab8-4f21-b6d3-d9d6d51ab988`
  },
  {
    role: 'user',
    program: 'glow',
    iframeUrl: `https://app.trevor.io/embedded/dashboard/ae48a98a-53ff-480d-97f4-628366e03e53`,
    programUrl: `https://app.trevor.io/embedded/dashboard/faaaad10-9ab8-4f21-b6d3-d9d6d51ab988`
  },
];
